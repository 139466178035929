import React from 'react';
import { User } from 'firebase/auth';

export const AuthContext = React.createContext<IUserContext>({
    user: null,
    setUserContext: () => {},
});

interface IUserContext {
    user: User | null;
    setUserContext: Function;
}
