import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: IFiltersSlice = {
    difficulty: 'default',
    company: 'default',
    prob_type: 'default',
};

export const filtersSlice = createSlice({
    name: 'filters',
    initialState,
    reducers: {
        updateFilters: (state, action: PayloadAction<IFiltersSlice>) => {
            const { company, difficulty, prob_type } = action.payload;

            return { ...initialState, company, difficulty, prob_type };
        },
        resetFilters: () => {
            return initialState;
        },
    },
});

export const { resetFilters, updateFilters } = filtersSlice.actions;

export default filtersSlice.reducer;

interface IFiltersSlice {
    difficulty: string;
    company: string;
    prob_type: string;
}
