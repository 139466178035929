import { createRoot } from 'react-dom/client';
import './scss/main.scss';
import { Main } from './Main';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { store } from './state/store';
import { Provider } from 'react-redux';
import { AuthProvider } from './auth/AuthProvider';

// Only Run In Production Environment
window.location.hostname !== 'localhost' &&
    Sentry.init({
        dsn: 'https://f75418885e9a43f08bd00f81b98731a9@o1228219.ingest.sentry.io/6373869',
        integrations: [new BrowserTracing()],
        tracesSampleRate: 0.1,
    });

const root = createRoot(document.getElementById('DAETAMA_ROOT') as HTMLElement);
root.render(
    <Provider store={store}>
        <AuthProvider>
            <Main />
        </AuthProvider>
    </Provider>
);
