import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { https } from '../../api/api';
import { appDebug } from '../../api/utils';

const initialState: IProblemSliceState = {
    loading: false,
    problem: {
        source_tables: [],
        solution_table: { columns: [], rows: [] },
        company_tags: [],
        hints: [],
        prob_type_tags: [],
        question: {
            code_language: '',
            created_at: '',
            description: '',
            difficulty: 'easy',
            is_premium: false,
            question_id: 0,
            solution_sql: '',
            solve_rate: 0,
            title: '',
            total_attempts: 0,
            video_solution_url: '',
        },
    },
    error: '',
};

export const questionSlice = createSlice({
    name: 'problem',
    initialState,
    reducers: {
        resetQuestions: () => {
            return initialState;
        },
    },
    extraReducers: (builder) => {
        // Fetch Individual Problem
        builder.addCase(getSingleQuestion.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getSingleQuestion.fulfilled, (state, action) => {
            state.loading = false;
            state.problem = { ...action.payload };
            state.error = '';
        });
        builder.addCase(getSingleQuestion.rejected, (state, action) => {
            state.loading = false;
            state.problem = { ...initialState.problem };
            state.error = 'Failed to find current question.';
            appDebug(action.error);
        });
    },
});

export const getSingleQuestion = createAsyncThunk(
    'problem/getSingleQuestion',
    async (id: number) => {
        const response = await https.get<IProblemResponse>(`/questions/${id}`);
        return await response.data;
    }
);

// Action creators are generated for each case reducer function
export const { resetQuestions } = questionSlice.actions;

export default questionSlice.reducer;

export type IProblemSliceState = {
    loading: boolean;
    problem: IProblemResponse;
    error: string;
};

export interface IProblemResponse {
    question: Question;
    company_tags: string[];
    prob_type_tags: string[];
    hints: string[];
    source_tables: SourceTable[];
    solution_table: Dataframe;
}

export interface Question {
    question_id: number;
    title: string;
    description: string;
    code_language: string;
    difficulty: 'easy' | 'med' | 'hard';
    video_solution_url: null | string;
    created_at: string;
    is_premium: boolean;
    solution_sql: string;
    solve_rate: number;
    total_attempts: number;
}

export interface SourceTable {
    table: Table;
    dataframe: Dataframe;
}

export interface Dataframe {
    columns: string[];
    rows: Row[];
}

export interface Row {
    tblname: Table;
    col_name: string;
    col_datatype: ColDatatype;
}

export enum ColDatatype {
    Bigint = 'bigint',
    Double = 'double',
    Text = 'text',
}

export enum Table {
    AirbnbDimProperty = 'airbnb_dim_property',
}
