import * as Sentry from '@sentry/react';

/** Sentry IO backed production grade debugger
 * @desc Only Runs in deployed environments
 */
export const appDebug = (error: unknown, additionalMessage?: string) => {
    const isDeployedEnvironment = window.location.hostname !== 'localhost';
    if (isDeployedEnvironment) {
        Sentry.captureException(error);
    }

    if (isDeployedEnvironment && additionalMessage) {
        Sentry.captureMessage(additionalMessage);
    }

    console.error(error);
};
