import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { https } from '../../api/api';
import { appDebug } from '../../api/utils';
import { store } from '../store';
import { getQuestionsByFilter } from './questionSlice';

const initialState: IUserSliceState = {
    loading: false,
    user: {
        email_optin: true,
        user_id: null,
        first_name: null,
        last_name: null,
        email: '',
        registered_at: '',
        is_premium: false,
        is_admin: false,
        is_confirmed: false,
        premium_ends_on: null,
    },
    error: '',
};

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        addUser: (state) => {
            return state;
        },
        resetUser: () => {
            return {
                loading: false,
                user: {
                    user_id: null,
                    first_name: null,
                    last_name: null,
                    email: '',
                    email_optin: true,
                    registered_at: '',
                    is_premium: false,
                    is_admin: false,
                    is_confirmed: false,
                    premium_ends_on: null,
                },
                error: '',
            };
        },
    },
    extraReducers: (builder) => {
        // Fetch Current User Code
        builder.addCase(fetchUser.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(fetchUser.fulfilled, (state, action) => {
            state.loading = false;
            state.user = { ...action.payload };
            state.error = '';
        });
        builder.addCase(fetchUser.rejected, (state, action) => {
            state.loading = false;
            state.user = { ...initialState.user };
            state.error = 'Failed to lookup current user';
            appDebug(action.error);
        });
    },
});

export const { addUser, resetUser } = userSlice.actions;

export const fetchUser = createAsyncThunk('user/fetchUser', async () => {
    const response = await https.get<IUserPayload>(`/whoami`);

    const { company, difficulty, prob_type } = store.getState().filters;

    await store.dispatch(
        getQuestionsByFilter({ company, difficulty, prob_type })
    );

    return await response.data;
});

export default userSlice.reducer;

export type IUserSliceState = {
    loading: boolean;
    user: IUserPayload;
    error: string;
};

export interface IUserPayload {
    user_id: string | null;
    first_name: null;
    last_name: null;
    email: string;
    registered_at: string;
    is_premium: boolean;
    email_optin: boolean;
    is_admin: boolean;
    is_confirmed: boolean;
    premium_ends_on: string | null;
}
