import React, { Suspense } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

const HomeView = React.lazy(() =>
    import('./pages/HomeView').then(({ HomeView }) => ({
        default: HomeView,
    }))
);

const SignUpView = React.lazy(() =>
    import('./pages/SignUpView').then(({ SignUpView }) => ({
        default: SignUpView,
    }))
);

const AccountView = React.lazy(() =>
    import('./pages/AccountView').then(({ AccountView }) => ({
        default: AccountView,
    }))
);

export const Main = () => {
    return (
        <Suspense fallback={<div>Loading ...</div>}>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<HomeView />} />
                    <Route path="/sign-up" element={<SignUpView />} />
                    <Route path="/account" element={<AccountView />} />
                    <Route path="*" element={<HomeView />} />
                </Routes>
            </BrowserRouter>
        </Suspense>
    );
};
