import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { https } from "../../api/api";
import { appDebug } from "../../api/utils";

const initialState: IQuestionsSliceState = {
  loading: false,
  questions: [],
  error: "",
};

export const questionSlice = createSlice({
  name: "questions",
  initialState,
  reducers: {
    resetQuestions: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    // Fetch Filtered Question Code
    builder.addCase(getQuestionsByFilter.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getQuestionsByFilter.fulfilled, (state, action) => {
      state.loading = false;
      state.questions = [...action.payload];
      state.error = "";
    });
    builder.addCase(getQuestionsByFilter.rejected, (state, action) => {
      state.loading = false;
      state.questions = [...initialState.questions];
      state.error = "Failed to submit current answer.";
      appDebug(action.error);
    });
  },
});

export const getQuestionsByFilter = createAsyncThunk(
  "questions/getQuestionsByFilter",
  async ({ company, difficulty, prob_type }: IFilteredQuestionsPayload) => {
    const qs = buildQueryString({ company, difficulty, prob_type });
    const { data } = await https.get<IQuestionsResponse[]>(`/questions${qs}`);
    return data;
  }
);
export interface IFilteredQuestionsPayload {
  company: string;
  prob_type: string;
  difficulty: string;
}

const buildQueryString = ({
  company,
  difficulty,
  prob_type,
}: IFilteredQuestionsPayload) => {
  const answer = [];

  if (company && company !== "default" && company.trim()) {
    answer.push(`company=${company.trim()}`);
  }

  if (difficulty && difficulty !== "default" && difficulty.trim()) {
    answer.push(`difficulty=${difficulty.trim()}`);
  }

  if (prob_type && prob_type !== "default" && prob_type.trim()) {
    answer.push(`prob_type=${prob_type.trim()}`);
  }
  return `?${answer.join("&")}`;
};

// Action creators are generated for each case reducer function
export const { resetQuestions } = questionSlice.actions;

export default questionSlice.reducer;

export interface IQuestionsResponse {
  question_id: number;
  company: string;
  title: string;
  difficulty: Difficulty;
  is_premium: boolean;
  solve_rate: null | number;
  total_attempts: null | number;
  is_solved: null | boolean;
  solved_sql: null | string;
  video_solution_url: null | string;
}

export enum Difficulty {
  Easy = "easy",
  Hard = "hard",
  Med = "med",
}

type IQuestionsSliceState = {
  loading: boolean;
  questions: IQuestionsResponse[];
  error: string;
};

// const shuffle = (array: any[]) => {
//     var m = array.length,
//         t,
//         i;

//     // While there remain elements to shuffle…
//     while (m) {
//         // Pick a remaining element…
//         i = Math.floor(Math.random() * m--);

//         // And swap it with the current element.
//         t = array[m];
//         array[m] = array[i];
//         array[i] = t;
//     }

//     return array;
// };
