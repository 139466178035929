import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { https } from '../../api/api';
import { appDebug } from '../../api/utils';
import { getQuestionsByFilter } from './questionSlice';
import { store } from '../store';
const initialState: ISubmissionsSliceState = {
    loading: false,
    isRunQueryLoading: false,
    submissions: {
        daetama_response_msg: '',
        dataframe: { columns: [], rows: [] },
        is_correct: false,
        question_id: 0,
        raw_sql_input: '',
        response_type: '',
        submission_id: 0,
        submitted_at: '',
        user_id: 0,
        user_response_msg: '',
        elapsedTime: null,
    },
    error: '',
};

export const submissionsSlice = createSlice({
    name: 'submissions',
    initialState,
    reducers: {
        resetSubmissions: () => {
            return initialState;
        },
    },
    extraReducers: (builder) => {
        // Submit Query Code
        builder.addCase(submitAnswer.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(submitAnswer.fulfilled, (state, action) => {
            state.loading = false;
            state.submissions = { ...action.payload };
            state.error = '';
        });
        builder.addCase(submitAnswer.rejected, (state, action) => {
            state.loading = false;
            state.submissions = { ...initialState.submissions };
            state.error = 'Failed to submit current answer.';
            appDebug(action.error);
        });

        // Run Query Code
        builder.addCase(submitRunQuery.pending, (state) => {
            state.isRunQueryLoading = true;
        });
        builder.addCase(submitRunQuery.fulfilled, (state, action) => {
            state.isRunQueryLoading = false;
            state.submissions = { ...action.payload };
            state.error = '';
        });
        builder.addCase(submitRunQuery.rejected, (state, action) => {
            state.isRunQueryLoading = false;
            state.submissions = { ...initialState.submissions };
            state.error = 'Failed to run current query.';
            appDebug(action.error);
        });
    },
});

export const { resetSubmissions } = submissionsSlice.actions;

export const submitAnswer = createAsyncThunk(
    'submissions/submitAnswer',
    async ({
        questionId,
        sql,
        is_gradeable = false,
    }: ISubmissionRequestPayload) => {
        const startTime = new Date().getTime();
        const { data } = await https.post<ISubmissionsPayload>(
            `/submissions/${questionId}`,
            {
                raw_input_sql: sql,
                is_gradeable,
            }
        );
        const endTime = new Date().getTime();
        const elapsedTime = `${endTime - startTime}ms`;

        // Refreshes Global Question List On Successful Answer Submission
        const { company, difficulty, prob_type } = store.getState().filters;
        await store.dispatch(
            getQuestionsByFilter({ company, difficulty, prob_type })
        );

        return { ...data, elapsedTime };
    }
);

export const submitRunQuery = createAsyncThunk(
    'submissions/runQuery',
    async ({
        questionId,
        sql,
        is_gradeable = false,
    }: ISubmissionRequestPayload) => {
        const startTime = new Date().getTime();
        const { data } = await https.post<ISubmissionsPayload>(
            `/submissions/${questionId}`,
            {
                raw_input_sql: sql,
                is_gradeable,
            }
        );

        const endTime = new Date().getTime();
        const elapsedTime = `${endTime - startTime}ms`;

        // Refreshes Global Question List On Successful Answer Submission
        const { company, difficulty, prob_type } = store.getState().filters;
        await store.dispatch(
            getQuestionsByFilter({ company, difficulty, prob_type })
        );

        return { ...data, elapsedTime };
    }
);

type ISubmissionRequestPayload = {
    questionId: number;
    sql: string;
    is_gradeable: boolean;
};

export default submissionsSlice.reducer;

export interface ISubmissionsPayload {
    submission_id: number;
    question_id: number;
    user_id: number;
    submitted_at: string;
    is_correct: boolean;
    raw_sql_input: string;
    response_type: string;
    user_response_msg: null | string;
    daetama_response_msg: null | string;
    dataframe: Dataframe;
    elapsedTime: string | null;
}

export interface Dataframe {
    columns: string[];
    rows: Row[];
}

export interface Row {
    [key: string]: string;
}

type ISubmissionsSliceState = {
    loading: boolean;
    isRunQueryLoading: boolean;
    submissions: ISubmissionsPayload;
    error: string;
};
