import { useEffect, useState } from 'react';
import { AuthContext } from './AuthContext';
import { auth } from './auth';
import { User } from 'firebase/auth';
import { useAppDispatch } from '../state/store';
import { fetchUser } from '../state/slices/userSlice';

type IAuthProviderProps = {
    children: JSX.Element | JSX.Element[];
};

export const AuthProvider = ({ children }: IAuthProviderProps) => {
    const [user, setUserContext] = useState<User | null>(null);
    const dispatch = useAppDispatch();

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
            if (user) {
                setUserContext(user);
                dispatch(fetchUser());
            }
        });

        return unsubscribe;
    }, [dispatch]);

    return (
        <AuthContext.Provider value={{ user, setUserContext }}>
            {children}
        </AuthContext.Provider>
    );
};
