import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import filtersSlice from './slices/filtersSlice';
import problemSlice from './slices/problemSlice';
import questionSlice from './slices/questionSlice';
import submissionsSlice from './slices/submissionsSlice';
import userSlice from './slices/userSlice';

export const store = configureStore({
    reducer: {
        user: userSlice,
        problem: problemSlice,
        filters: filtersSlice,
        questions: questionSlice,
        submissions: submissionsSlice,
    },
    devTools: true,
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
