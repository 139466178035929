import { initializeApp } from 'firebase/app';
import { getAuth, GithubAuthProvider, GoogleAuthProvider } from 'firebase/auth';
import { getAnalytics } from 'firebase/analytics';

export interface CustomWindowForAuth extends Window {
    DAETAMA_UI_VARS: {
        apiKey: string;
        authDomain: string;
        projectId: string;
        storageBucket: string;
        messagingSenderId: string;
        appId: string;
        measurementId: string;
        env: string;
        stripePublicKey: string;
        apiUrl: string;
    };
}

declare let window: CustomWindowForAuth;

const AUTH_CONFIG = {
    apiKey: window.DAETAMA_UI_VARS.apiKey,
    authDomain: window.DAETAMA_UI_VARS.authDomain,
    projectId: window.DAETAMA_UI_VARS.projectId,
    storageBucket: window.DAETAMA_UI_VARS.storageBucket,
    messagingSenderId: window.DAETAMA_UI_VARS.messagingSenderId,
    appId: window.DAETAMA_UI_VARS.appId,
    measurementId: window.DAETAMA_UI_VARS.measurementId,
    env: window.DAETAMA_UI_VARS.env,
    stripePublicKey: window.DAETAMA_UI_VARS.stripePublicKey,
    apiUrl: window.DAETAMA_UI_VARS.apiUrl,
};

// Initialize Firebase
export const app = initializeApp(
    AUTH_CONFIG,
    `DAETAMA-IO-AUTH${window.DAETAMA_UI_VARS.env}`
);
export const analytics = getAnalytics(app);

export const googleProvider = new GoogleAuthProvider();

export const githubProvider = new GithubAuthProvider();

export const auth = getAuth(app);
